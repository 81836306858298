var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  var currentBreakpoint; // Global

  function checkBreakpoint() {
    currentBreakpoint = window.getComputedStyle(document.body,':after').getPropertyValue('content');
    currentBreakpoint = parseInt(currentBreakpoint.split('-')[1]);
  }

  function initQuickNavMenu() {
    $('.header__quicknav > .nav > .module-nav__item').bind('mouseenter', function() {
      $(this).removeClass('-faded').siblings().addClass('-faded');

    }).bind('mouseleave', function() {
      $(this).removeClass('-faded').siblings().removeClass('-faded');
    });
  }

  function initMainMenu() {
    $('.header__mainmenu .module-nav__lvl1 > .module-nav__item').each(function(index) {
      $(this).addClass('module-nav__item--'+index);
    });

    $('.header__mainmenu .module-nav__lvl1 > .module-nav__item--hasSubpages > a, .header__mainmenu .module-nav__lvl2 > .module-nav__item--hasSubpages > a').bind('click', function(e) {
      if(currentBreakpoint == 0) {
        e.preventDefault();
      }
    }).bind('mousedown', function() {
      if(currentBreakpoint == 0) {
        if(!$(this).parent().hasClass('-opened')) {
          $(this).parent().addClass('-opened');
        }else{
          $(this).parent().removeClass('-opened');
        }
      }else{
        $(this).parent().removeClass('-opened');
      }
    });
    $('.header__mainmenu .module-nav__item--active.module-nav__item--hasSubpages > a').trigger('mousedown');

    $('.header__mainmenu-toggle').bind('mousedown', function() {
      if($('.header__area--two').hasClass('-open')) {
        $('.header__area--one, .header__area--two, .header__area--three, .page__header .more-button').removeClass('-open');
      } else {
        $('.header__area--one, .header__area--two, .header__area--three, .page__header .more-button').addClass('-open');
      }
    });
  }

  function checkMainMenuState() {
    if(currentBreakpoint != 0) {
      $('.header__mainmenu .module-nav__lvl1 > .module-nav__item').removeClass('-opened');
    }
  }

  function setMainMenuHeight() {
    if(currentBreakpoint == 0) {
      $('.page__header .header__area--two').css('max-height', window.innerHeight - $('.header__area--one').height());
    }else{
      $('.page__header .header__area--two').removeAttr('style');
    }
  }

  var secondLevelSwiper;
  function initMainMenu2nd() {

    $('.header__mainmenu2nd > .swiper-wrapper > .module-nav__item').bind('mouseenter', function() {
      $(this).removeClass('-faded').siblings().addClass('-faded');

    }).bind('mouseleave', function() {
      $(this).removeClass('-faded').siblings().removeClass('-faded');
    });

    if(!$('.header__area--three .header__mainmenu2nd ul').length) {
      $('.header__area--three').remove();
    }else{
      $('body').addClass('-mainmenu2nd-present');

      var initialSlide = 0;
      $('.header__area--three .header__mainmenu2nd > ul > .module-nav__item').each(function(index) {
        if($(this).hasClass('module-nav__item--active')) {
          initialSlide = index;
        }
      });

      secondLevelSwiper = new Swiper($('.header__area--three .header__mainmenu2nd'), {
        initialSlide: initialSlide,
        navigation: {
          nextEl: $('.header__area--three .header__mainmenu2nd').find('.swiper-nav-button--next'),
          prevEl: $('.header__area--three .header__mainmenu2nd').find('.swiper-nav-button--prev')
        },
        grabCursor: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        freeMode: true,
        freeModeSticky: true,
        roundLengths: true,
        breakpoints: {
          1023: {
            centeredSlides: true
          },
          9999: {
            centeredSlides: false
          }
        }
      });
    }
  }

  function updateMainMenu2nd() {
    if(secondLevelSwiper) {
      secondLevelSwiper.update();
    }
  }

  $(document).ready(function() {
    checkBreakpoint();
    initQuickNavMenu();
    initMainMenu();
    setMainMenuHeight();
    initMainMenu2nd();

    window.setInterval(function() {
      setMainMenuHeight();
    }, 2000)
  });

  if(document.readyState === 'complete') {
    checkBreakpoint();
    setMainMenuHeight();
  }else{
    $(window).on('load', function() {
      checkBreakpoint();
      setMainMenuHeight();
    });
  }

  $(window).resize(function() {
    checkBreakpoint();
    setMainMenuHeight();
  });

  var rtime;
  var timeout = false;
  var delta = 150;
  $(window).resize(function() {
    // Trigger the resizeend function
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  // Initialize a resizeend function
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      // Functions to fire after resize has stopped
      updateMainMenu2nd();
      checkMainMenuState();
    }
  }

  return communiacsModules;

})( communiacsModules || {} );
