var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function setImageSize() {
    $('.module-hcard .card__image').each(function() {
      // Get Base Values
      var elemWidth = $(this).find('.image-preloaded__loaded img').width();
      var elemHeight = $(this).find('.image-preloaded__loaded img').height();
      var containerWidth = $(this).width();
      var containerHeight = $(this).height();

      // Calculate new Dimensions for width:100%
      var oldW = elemWidth;
      var oldH = elemHeight;
      var newW = containerWidth + 2;
      var newH = (newW / oldW) * oldH;

      // Check if new Height of the Background is smaller than the container
      if(newH < containerHeight) {
        newH = containerHeight + 2;
        newW = (newH / oldH) * oldW;
      }

      // Set the new Dimensions
      jQuery(this).find('.module-image-preloaded').css('width', newW).css('height', newH);
    });
  }

  $(document).ready(function() {
    setImageSize();
  });

  if(document.readyState === 'complete') {
    setImageSize();
  } else {
    $(window).on('load', function() {
      setImageSize();
    });
  }

  $(window).resize(function() {
    setImageSize();
  });

  return communiacsModules;

})( communiacsModules || {} );
