var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initSwiper() {
    var ctaSlider = [];

    $('.module-cta-slider').each(function(index) {
      ctaSlider[index] = new Swiper($(this).find('.swiper-container:first'), {
        spaceBetween: 39,
        slidesPerView: 'auto',
        scrollbar: {
          el: $(this).find('.swiper-scrollbar'),
          hide: false,
          draggable: true
        },
        grabCursor: true,
        freeMode: true
      });
    });
  }

  $(document).ready(function() {
    initSwiper();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  $(window).resize(function() {

  });

  return communiacsModules;

})( communiacsModules || {} );
