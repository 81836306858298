var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  var psGallerySwiper;
  function initPhotoswipe() {
    if($('.ext-images').length) {
      if(!$('.pswp').length) {
        // Add photoswipe ui
        $('body').append('<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"> <div class="pswp__bg"></div> <div class="pswp__scroll-wrap"> <div class="pswp__container"> <div class="pswp__item"></div> <div class="pswp__item"></div> <div class="pswp__item"></div> </div> <div class="pswp__ui pswp__ui--hidden"> <div class="pswp__top-bar"> <div class="pswp__counter"></div> <button class="pswp__button pswp__button--close" title="Close (Esc)"></button> <button class="pswp__button pswp__button--share" title="Share"></button> <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button> <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button> <div class="pswp__preloader"> <div class="pswp__preloader__icn"> <div class="pswp__preloader__cut"> <div class="pswp__preloader__donut"></div> </div> </div> </div> </div> <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"> <div class="pswp__share-tooltip"></div> </div> <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"> </button> <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"> </button> <div class="pswp__caption"> <div class="pswp__caption__center"></div> </div> </div> </div> <div class="pswp__thumbnails-wrapper swiper-container"><div class="pswp__thumbnails swiper-wrapper"></div></div> </div>');

        // Init swiper
        psGallerySwiper = new Swiper($('.pswp .swiper-container'), {
          spaceBetween: 0,
          slidesPerView: 'auto',
          grabCursor: true,
          freeMode: true,
          centeredSlides: true
        });

        $('.pswp .swiper-container').on('mousedown', '.thumbnails__thumbnail', function() {
          var currentId = parseInt($(this).attr('data-id'));
          psGallery.goTo(currentId);
        });
      }

      var psGallery;
      var openPsGallery = function(startingId) {
        // Set variables for PhotoSwipe
        var pswpElement = document.querySelectorAll('.pswp')[0];
        var options = {
          index: startingId,
          shareEl: false,
          history: false
        };

        // Add Thumbnails
        $('.pswp .pswp__thumbnails').html('');
        $.each(psThumbnails, function(key, val) {
          $('.pswp .pswp__thumbnails').append('<div class="thumbnails__thumbnail swiper-slide" data-id="'+key+'"><img src="'+val.src+'"></div>');
        });

        // Update the swiper instance
        psGallerySwiper.update();
        window.setTimeout(function() {
          psGallerySwiper.update();
        }, 500);

        // Create a new instance
        psGallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, psItems, options);
        psGallery.init();

        // Switch swiper to first slide
        window.setTimeout(function() {
          psGallerySwiper.slideTo(psGallery.getCurrentIndex());
          $('.pswp .thumbnails__thumbnail').eq(psGallery.getCurrentIndex()).addClass('-ps-active').siblings().removeClass('-ps-active');
        }, 500);

        // Add listeners
        psGallery.listen('afterChange', function() {
          psGallerySwiper.slideTo(psGallery.getCurrentIndex());
          $('.pswp .thumbnails__thumbnail').eq(psGallery.getCurrentIndex()).addClass('-ps-active').siblings().removeClass('-ps-active');
        });
      };


      // Set the mousedown events
      $('.ext-images .images__image').bind('mousedown', function() {
        openPsGallery(parseInt($(this).attr('data-id')));
      });
    }
  }

  $(document).ready(function() {
    initPhotoswipe();
  });

  return communiacsModules;

})( communiacsModules || {} );
