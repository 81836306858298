var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initSwiper() {
    var imageSlider = [];

    $('.module-image-swiper').each(function(index) {
      imageSlider[index] = new Swiper($(this).find('.swiper-container:first'), {
        spaceBetween: 20,
        pagination: {
          el: $(this).find('.swiper-pagination'),
          clickable: true
        },
        grabCursor: true
      });
    });
  }

  function setSwipeableClass() {
    // Set the -swipeable class to set a padding-bottom for the pagination
    $('.swiper-container').each(function() {
      if($(this).find('.swiper-slide').length > 1) {
        $(this).addClass('-swipeable');
      }else{
        $(this).removeClass('-swipeable');
      }
    });
  }

  $(document).ready(function() {
    initSwiper();
    setSwipeableClass();
    window.setInterval(function() { setSwipeableClass(); }, 1000);
  });

  if(document.readyState === 'complete') {
    setSwipeableClass();
  }else{
    $(window).on('load', function() {
      setSwipeableClass();
    });
  }

  $(window).resize(function() {
    setSwipeableClass();
  });

  return communiacsModules;

})( communiacsModules || {} );
