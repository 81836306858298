var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  var cardSlider = [];
  function initSwiper() {
    $('.module-pricelist.swiper-container').each(function(index) {
      // Read the current gutter width. If not present, use a default value instead
      var currentGutterWidth = parseInt($(this).css('padding-bottom'));
      if(currentGutterWidth === 0) {
        currentGutterWidth = 50;
      }

      cardSlider[index] = new Swiper($(this), {
        spaceBetween: currentGutterWidth,
        slidesPerView: 3,
        pagination: {
          el: $(this).find('.swiper-pagination'),
          clickable: true
        },
        grabCursor: true,
        breakpoints: {
          639: {
            slidesPerView: 1
          },
          1023: {
            slidesPerView: 2
          },
          9999: {
            slidesPerView: 3
          }
        }
      });
    });
  }

  function updateSwiper() {
    $('.module-pricelist.swiper-container').each(function(index) {
      // Read the current gutter width. If not present, use a default value instead
      var currentGutterWidth = parseInt($(this).css('padding-bottom'));
      if(currentGutterWidth === 0) {
        currentGutterWidth = 50;
      }

      cardSlider[index].params.spaceBetween = currentGutterWidth;
      cardSlider[index].update();
    });
  }

  $(document).ready(function() {
    initSwiper();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  $(window).resize(function() {

  });

  var rtime;
  var timeout = false;
  var delta = 150;
  $(window).resize(function() {
    // Trigger the resizeend function
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  // Initialize a resizeend function
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      // Functions to fire after resize has stopped
      updateSwiper();
    }
  }

  return communiacsModules;

})( communiacsModules || {} );
