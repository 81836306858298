var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initQuoteNav() {
    $('.module-quotes').each(function() {
      // Add Classes
      $(this).find('.quotes__quote:first').addClass('-active');
      $(this).find('.images__image:first').addClass('-active');

      // Adjust height
      var targetQuote = $(this).find('.quotes__quote.-active');
      var newHeight = targetQuote.find('.quote__text').outerHeight(true) + targetQuote.find('.quote__source').outerHeight(true);
      $(this).find('.quotes__quotes').css('height', newHeight);

      // Typing
      var source = $(this).find('.quotes__quote.-active p.-original');
      var target = $(this).find('.quotes__quote.-active p.-typed');

      target.typed({
        strings: [source.text()],
        typeSpeed: 0,
        showCursor: false,
        contentType: 'null'
      });
    });

    $('.module-quotes .nav__left span').bind('mousedown', function() {
      var currentModule = $(this).closest('.module-quotes');

      // Determine next element
      if(currentModule.find('.quotes__quote.-active').prev('.quotes__quote').length) {
        var nextEl = currentModule.find('.quotes__quote.-active').prev('.quotes__quote');
      }else{
        var nextEl = currentModule.find('.quotes__quote:last');
      }

      switchQuote(currentModule, nextEl);
    });

    $('.module-quotes .nav__right span').bind('mousedown', function() {
      var currentModule = $(this).closest('.module-quotes');

      // Determine next element
      if(currentModule.find('.quotes__quote.-active').next('.quotes__quote').length) {
        var nextEl = currentModule.find('.quotes__quote.-active').next('.quotes__quote');
      }else{
        var nextEl = currentModule.find('.quotes__quote:first');
      }

      switchQuote(currentModule, nextEl);
    });
  }

  function switchQuote(currentModule, nextEl) {
    // Set new classes
    nextEl.addClass('-active').siblings().removeClass('-active');
    currentModule.find('.images__image[data-id='+nextEl.attr('data-id')+']').addClass('-active').siblings().removeClass('-active');

    // Adjust new height
    var newHeight = nextEl.find('.quote__text').outerHeight(true) + nextEl.find('.quote__source').outerHeight(true);
    currentModule.find('.quotes__quotes').css('height', newHeight);

    // Clear typed strings and type new quote
    currentModule.find('-typed').text('');

    var source = nextEl.find('p.-original');
    var target = nextEl.find('p.-typed');

    target.typed({
      strings: [source.text()],
      typeSpeed: 0,
      showCursor: false,
      contentType: 'null'
    });
  }

  function updateQuoteHeight() {
    $('.module-quotes').each(function() {
      // Adjust height
      var targetQuote = $(this).find('.quotes__quote.-active');
      var newHeight = targetQuote.find('.quote__text').outerHeight(true) + targetQuote.find('.quote__source').outerHeight(true);
      $(this).find('.quotes__quotes').css('height', newHeight);
    });
  }

  function updateImageShift() {
    var currentSectionMargin = parseFloat($('.module-quotes:first').css('padding-bottom'));

    $('.module-quotes .quotes__images').css('margin-bottom', currentSectionMargin * -1);
  }

  $(document).ready(function() {
    updateImageShift();
  });

  if(document.readyState === 'complete') {
    initQuoteNav();
    updateImageShift();
  }else{
    $(window).on('load', function() {
      initQuoteNav();
      updateImageShift();
    });
  }

  $(window).resize(function() {
    updateImageShift();
  });

  var rtime;
  var timeout = false;
  var delta = 150;
  $(window).resize(function() {
    // Trigger the resizeend function
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  // Initialize a resizeend function
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      // Functions to fire after resize has stopped
      updateQuoteHeight();
      updateImageShift();
    }
  }

  return communiacsModules;

})( communiacsModules || {} );
