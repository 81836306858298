var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function setBackgroundSize() {
    $('.module-multi-billboard .billboard__background').each(function() {
      // Get Base Values
      var elemWidth = $(this).find('.image-preloaded__loaded img').width();
      var elemHeight = $(this).find('.image-preloaded__loaded img').height();
      var containerWidth = $(this).width();
      var containerHeight = $(this).height();

      // Calculate new Dimensions for width:100%
      var oldW = elemWidth;
      var oldH = elemHeight;
      var newW = containerWidth + 2;
      var newH = (newW / oldW) * oldH;

      // Check if new Height of the Background is smaller than the container
      if(newH < containerHeight) {
        newH = containerHeight + 2;
        newW = (newH / oldH) * oldW;
      }

      // Set the new Dimensions
      jQuery(this).find('.module-image-preloaded').css('width', newW).css('height', newH);
    });
  }

  function setGridPadding() {
    if($('.module-multi-billboard').length) {
      var currentBreakpoint = window.getComputedStyle(document.body,':after').getPropertyValue('content');
      currentBreakpoint = parseInt(currentBreakpoint.split('-')[1]);

      if(currentBreakpoint >= 2) {
        var currentMargin = ($(window).width() - $('.page__footer .footer-wrap').width()) / 2;

        $('.module-multi-billboard').each(function() {
          if($(this).find('.multi-billboard__billboard').length <= 2) {
            $('.module-multi-billboard .multi-billboard__billboard:first .billboard__content').css('padding-left', currentMargin);
            $('.module-multi-billboard .multi-billboard__billboard:last .billboard__content').css('padding-right', currentMargin);
          }
        });
      }else{
        $('.module-multi-billboard .multi-billboard__billboard .billboard__content').removeAttr('style');
      }
    }
  }

  $(document).ready(function() {
    setBackgroundSize();
    setGridPadding();
  });

  if(document.readyState === 'complete') {
    setBackgroundSize();
    setGridPadding();
  } else {
    $(window).on('load', function() {
      setBackgroundSize();
      setGridPadding();
    });
  }

  $(window).resize(function() {
    setBackgroundSize();
    setGridPadding();
  });

  return communiacsModules;

})( communiacsModules || {} );
