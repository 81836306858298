var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function checkForResponsiveTables() {
    // Checks for responsive tables, gives them class names and adds data-attributes to the table cells (filled with
    // labels for mobile view)
    $('table').each(function() {
      if($(this).find('thead').length) {
        // Set the labels
        $(this).find('th').each(function(index) {
          var currentIndex = index;
          var currentText = $(this).text();
          $(this).closest('table').find('tr').each(function() {
            $(this).find('td').eq(currentIndex).attr('data-label', currentText);
          });
        });

        // Wrap in span tags (for width calculations)
        $(this).find('th, td').contents().wrap('<span></span>');

        // Add even/odd class names to the cells
        var evenOddCounter = 0;
        $(this).find('tr').each(function() {
          if(evenOddCounter === 0) {
            $(this).addClass('tr-odd');
            evenOddCounter = 1;
          }else{
            $(this).addClass('tr-even');
            evenOddCounter = 0;
          }
          var evenOddCounter2 = 0;
          var index = 0;
          $(this).find('td').each(function() {
            if(evenOddCounter2 === 0) {
              $(this).addClass('td-odd');
              evenOddCounter2 = 1;
            }else{
              $(this).addClass('td-even');
              evenOddCounter2 = 0;
            }
            $(this).css('animation-delay', index/20 + 's');
            index++;
          });
        });
      }
    });
  }

  function checkResponsiveTableCellWidths() {
    // Checks on resize if one of the cells is becomming to small for its content and if so, switches to mobile view
    $('table').each(function() {
      // First revert to normal state
      var tableTooSmall = 0;
      $(this).removeClass('table-mobile');
      $(this).find('td:first-child').each(function () {
        $(this).unbind('mousedown');
      });
      $(this).find('.td_label').remove();

      // Check for too small cells
      if(parseInt($(this).width()) > parseInt(Math.ceil($(this).parent().width()))) {
        tableTooSmall = 1;
        $(this).parent().attr('data-debug','tooSmall-'+$(this).width()+'-'+$(this).parent().width());
      }
    });
  }

  $(document).ready(function() {
    checkForResponsiveTables();
    checkResponsiveTableCellWidths();
  });

  $(window).on('load', function() {
    checkResponsiveTableCellWidths();
  });

  $(window).resize(function() {
    checkResponsiveTableCellWidths();
  });

  return communiacsModules;

})( communiacsModules || {} );
