var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initAccordion() {
    $('.module-accordion .accordion__item').each(function() {
      $(this).find('.item__title').bind('mousedown', function() {
        if(!$(this).closest('.accordion__item').hasClass('-opened')) {
          $(this).closest('.accordion__item').addClass('-opened')
        }else{
          $(this).closest('.accordion__item').removeClass('-opened')
        }
      });
    });
  }

  $(document).ready(function() {
    initAccordion();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  $(window).resize(function() {

  });

  return communiacsModules;

})( communiacsModules || {} );
