var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initSidebarMenu() {
    $('.module-nav--sidebar .sidebar__menu-toggle').bind('mousedown', function() {
      if(!$(this).parent().hasClass('-opened')) {
        $(this).parent().addClass('-opened');
      }else{
        $(this).parent().removeClass('-opened');
      }
    });
  }

  $(document).ready(function() {
    initSidebarMenu();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  $(window).resize(function() {

  });

  return communiacsModules;

})( communiacsModules || {} );
