var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  var hrjs;
  function initHeadroomJs() {
    if($('.page__header').length) {
      var hrjsEl = $('.page__header')[0];
      hrjs  = new Headroom(hrjsEl, {
        offset: 60,
        tolerance: 20,
        classes: {
          initial: "hrjs",
          pinned: "hrjs--pinned",
          unpinned: "hrjs--unpinned",
          top: "hrjs--top",
          notTop: "hrjs--not-top",
          bottom: "hrjs--bottom",
          notBottom: "hrjs--not-bottom"
        },
        onUnpin: function() {
          if($('.header__area--two').hasClass('-open')) {
            $('.header__mainmenu-toggle').trigger('mousedown');
          }
        }
      });

      hrjs.init();
    }
  }

  function resizeIframes() {
    $('iframe').each(function() {
      if($(this).attr('data-width') != '') {
        var oldWidth = jQuery(this).attr('data-width');
        var oldHeight = jQuery(this).attr('data-height');
      }else{
        var oldWidth = jQuery(this).attr('width');
        var oldHeight = jQuery(this).attr('height');
      }
      var newWidth = jQuery(this).parent().width();
      var newHeight = (newWidth / oldWidth) * oldHeight;

      jQuery(this).attr('width',newWidth).attr('height',newHeight);
    });
  }

  function initBackToTop() {
    $('.breadcrumb__btt').bind('mousedown', function() {
      $('html, body').animate({scrollTop: 0}, 500);
    });
  }

  function initAnchorLinks() {
    $('a[href*="#"]').click(function(e) {
      if($($(this).prop('hash')).length) {
        e.preventDefault();

        var scrollTo = parseInt($($(this).prop('hash')).offset().top) - 100;
        if(scrollTo < 0) {
          scrollTo = 0;
        }

        $('html, body').stop().animate({scrollTop: scrollTo}, 500);
      }
    });
  }

  $(document).ready(function() {
    initHeadroomJs();
    resizeIframes();
    initBackToTop();
    initAnchorLinks();
  });

  if(document.readyState === 'complete') {
    resizeIframes();
  }else{
    $(window).on('load', function() {
      resizeIframes();
    });
  }

  $(window).resize(function() {
    resizeIframes();
  });

  var rtime;
  var timeout = false;
  var delta = 150;
  $(window).resize(function() {
    // Trigger the resizeend function
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  });

  // Initialize a resizeend function
  function resizeend() {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;

      // Functions to fire after resize has stopped
      resizeIframes();
    }
  }

  return communiacsModules;

})( communiacsModules || {} );
