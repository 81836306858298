var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initBreadcrumbSwiper() {
    var mySwiper = new Swiper ('.breadcrumb__info', {
      speed: 500,
      loop: true,
      autoplay: {
        delay: 3000
      }
    })
  }

  $(document).ready(function() {
    initBreadcrumbSwiper();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  $(window).resize(function() {

  });

  return communiacsModules;

})( communiacsModules || {} );
