var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  function initTabNav() {
    $('.module-tab .tab__nav .nav__item').each(function() {
      $(this).bind('mousedown', function() {
        $(this).addClass('-active').siblings().removeClass('-active');
        $(this).closest('.module-tab').find('.tabs__tab[data-id="'+$(this).data('id')+'"]').addClass('-active').siblings().removeClass('-active');
      });
    });

    $('.module-tab .tabs__tab .tab__title').each(function() {
      $(this).bind('mousedown', function() {
        if(!$(this).hasClass('-active-m')) {
          $(this).addClass('-active-m');
          $(this).closest('.module-tab').find('.tabs__tab[data-id="'+$(this).data('id')+'"]').addClass('-active-m');
        }else{
          $(this).removeClass('-active-m');
          $(this).closest('.module-tab').find('.tabs__tab[data-id="'+$(this).data('id')+'"]').removeClass('-active-m');
        }
      });
    });

    $('.module-tab').each(function() {
      $(this).find('.tab__nav .nav__item:first').trigger('mousedown');
    });
  }

  $(document).ready(function() {
    initTabNav();
  });

  if(document.readyState === 'complete') {

  } else {
    $(window).on('load', function() {

    });
  }

  return communiacsModules;

})( communiacsModules || {} );
